module.exports = {
  'webDevelopment': 'Web development',
  'callUs': 'Give us a call',
  'homePage': 'Home',
  'servicesPage': 'Services',
  'portfolioPage': 'Portfolio',
  'aboutUsPage': 'About us',
  'contactPage': 'Contact us',
  'legalPage': 'Legal policy',
  'privacyPage': 'Privacy policy',
  'cookiesPage': 'Cookies policy',
  'footerCopyrights': 'All rights reserved',
  'portfolioTitle': 'Our projects',
  'portfolioSubtitle': 'These are some of the projects we like to highlight.',
  'testimonialsTitle': 'Testimonials',
  'testimonialsSubtitle': 'What people are saying <span class="clr-yeloow">about us</span>',
  'customersTitle': 'Many names has <span class="clr-yeloow">trusted us</span>',
  'customersSubtitle': 'We’ve worked with hundreds of clients including:',
  '404Title': 'Whoops! Page not found',
  '404Subtitle': 'It seems that the page you are looking for does not exist ...',
  '404Button': 'Back to home',
  'contactTitle':'How can we help you?',
  'messageSentError':'Something went wrong',
  'messageSentOk':'Thank you for contacting us! We will answer you shortly',
  'nameLabel':'Name',
  'emailLabel':'Email',
  'messageLabel':'Tell us what you need',
  'accetpAction': 'I accept the',
  'accetpPrivacy': 'privacy policy',
  'sendAction': 'Send message',
  'portfolioClientLabel': 'Client',
  'portfolioServicesLabel': 'Services',
  'portfolioStackLabel': 'Stack',
  'portfolioDateLabel': 'Date',
  'portfolioWebsiteLabel': 'Website',
  'portfolioChallengeLabel': 'Challenge',
  'portfolioLinkLabel': 'More info',
};