import * as React from "react"
import Layout from "../components/layout/en"

import { StaticImage } from "gatsby-plugin-image"

// markup
const ServiciosPage = (props) => {

  return (
    <Layout location={props.location} pageId="services" metaTitle="Nuestros servicios | Crequs">
      <section className="service_v1_page">
        <div className="container">
          <div className="row service_v1_content">
            <div className="col-lg-6">
              <div className="sv-title">
                <h2>Creamos productos y experiencias digitales a medida.</h2>
                <p>Somos expertos en Drupal, Wordpress, React y optimización de páginas web.</p>
              </div>
              <div className="services_items">
                <div className="service-item">
                  <div className="service-thumb">
                                      <StaticImage
                                          src="../images/illustracion/about-js.svg"
                                          alt="Crequs"
                                          width={500}
                                          loading="eager"
                                          backgroundColor="transparent"
                                          placeholder="tracedSVG"
                                      />
                  </div>
                  <div className="service-info">
                    <h3>Javascript</h3>
                    <p>Nos encanta la agilidad y la frescura que traen los nuevos frameworks de Javascript.</p>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="sk-col">
                          <h4>Frameworks</h4>
                          <ul>
                            <li><span>React</span></li>
                            <li><span>GatsbyJS</span></li>
                            <li><span>NextJS</span></li>
                            <li><span>GraphQL</span></li>
                            <li><span>jQuery</span></li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="sk-col pol">
                          <h4 className="top">Qué realizamos</h4>
                          <ul>
                            <li><span>Maquetación a medida, páginas de aterrizaje, formularios a medida, visualización de datos, web app, integraciones con Wordpress y Drupal, Headless CMS, Jamstack, sitios web estáticos</span></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="service-item">
                  <div className="service-thumb">
                                      <StaticImage
                                          src="../images/illustracion/about-seo.svg"
                                          alt="Crequs"
                                          width={500}
                                          loading="eager"
                                          backgroundColor="transparent"
                                          placeholder="tracedSVG"
                                      />
                  </div>
                  <div className="service-info">
                    <h3>Marketing &amp; SEO</h3>
                    <p>Colaboramos con numerosas agencias de marketing ofreciendo servicios de implementación técnica de estrategias de marketing</p>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="sk-col">
                          <h4>Stacks</h4>
                          <ul>
                            <li><span>Google: Tag Manager, Analytics, Optimize</span></li>
                            <li><span>Adforms</span></li>
                            <li><span>Facebook API</span></li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="sk-col pol">
                          <h4 className="top">Qué implementamos</h4>
                          <ul>
                            <li><span>Plan de medición, integración de script, configuración de política de privacidad y de cookies, consultoria, optimización SEO on-site, test a/b</span></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="services_items style2">
                <div className="service-item ml-auto">
                  <div className="service-thumb">
                                      <StaticImage
                                          src="../images/illustracion/about-php.svg"
                                          alt="Crequs"
                                          width={500}
                                          loading="eager"
                                          backgroundColor="transparent"
                                          placeholder="tracedSVG"
                                      />
                  </div>
                  <div className="service-info">
                    <h3>PHP</h3>
                    <p>Estamos especializados en desarrollos a medida con los mejores gestores de contenidos del mercado.</p>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="sk-col">
                          <h4>Gestores de contenidos</h4>
                          <ul>
                            <li><span>Wordpress</span></li>
                            <li><span>Drupal</span></li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="sk-col pol">
                          <h4 className="top">Qué ofrecemos</h4>
                          <ul>
                            <li><span>Optimización velocidad de carga, páginas de aterrizaje, temas y módulos a medida, maquetación a medida, e-commerce, integraciones con CRM, API Rest, optimización SEO on page</span></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="service-item ml-auto">
                  <div className="service-thumb">
                                      <StaticImage
                                          src="../images/illustracion/about-api.svg"
                                          alt="Crequs"
                                          width={500}
                                          loading="eager"
                                          backgroundColor="transparent"
                                          placeholder="tracedSVG"
                                      />
                  </div>
                  <div className="service-info">
                    <h3>API</h3>
                    <p>Integramos numerosas herramientas y hemos trabajado con decenas de API</p>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="sk-col">
                          <h4>Plataformas</h4>
                          <ul>
                            <li><span>CRM: Zoho, Salesforce</span></li>
                            <li><span>Active Campaign</span></li>
                            <li><span>Mailchimp</span></li>
                            <li><span>Payments: Stripe, Redsys</span></li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="sk-col pol">
                          <h4 className="top">Cómo podemos ayudarte</h4>
                          <ul>
                            <li><span>Integraciones avanzadas, pagos recurrentes, consultoría, visualización de datos, web scraping</span></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ServiciosPage


