import React from 'react';
import Layout from '../layout';

import messages from '../../data/messages/en';

export default (props) => (
  <Layout
    {...props}
    i18nMessages={messages}
  />
);